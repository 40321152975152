@import url(https://fonts.googleapis.com/css2?family=Dosis&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Dosis', sans-serif;
	background-color: #1a1a1a;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
s ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar {
	width: 12px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #555;
}

* {
	scroll-behavior: smooth;
}

.phone-input {
	width: 100% !important;
}

.special-label {
	font-weight: 600;
	color: #2541b2;
	font-family: 'Dosis', sans-serif;
}

